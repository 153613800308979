import { FaFacebook, FaInstagram, FaEnvelope, FaLinkedin, FaYoutube } from "react-icons/fa";

export const socialMedia = [
  {
    Name: "Facebook",
    Link: "https://www.facebook.com/Kevin.Italiya.KV/",
    Icon: <FaFacebook className="w-6 h-6" />,
  },
  {
    Name: "Instagram",
    Link: "https://www.instagram.com/lazy_but_programmer/",
    Icon: <FaInstagram className="w-6 h-6" />,
  },
  {
    Name: "Youtube",
    Link: "https://www.youtube.com/@naturerelaxtv",
    Icon: <FaYoutube className="w-6 h-6" />,
  },
  // {
  //   Name: "Github",
  //   Link: "https://github.com/KEVINITALIYA",
  //   Icon: <FaGithub className="w-6 h-6" />,
  // },
  {
    Name: "LinkedIn",
    Link: "https://www.linkedin.com/in/kevin-italiya-8458b416a/",
    Icon: <FaLinkedin className="w-6 h-6" />,
  },
  // {
  //   Name: "Website",
  //   Link: "https://www.kevinitaliya.com/",
  //   Icon: <FaGlobe className="w-6 h-6" />,
  // },
  {
    Name: "Gmail",
    Link: "mailto:hi@kevinitaliya.info",
    Icon: <FaEnvelope className="w-6 h-6" />,
  },
];