import React from "react";
import { FaUndo } from "react-icons/fa";

const Undo = ({ handleResetClick }) => {
  return (
    <div className="hidden md:block fixed flex justify-center bottom-60 shadow-2xl right-0 mr-12">
      <button
        title="Reset"
        onClick={handleResetClick}
        className="h-12 w-12 px-4 py-2 bg-orange-100 text-black rounded-full shadow-lg hover:bg-orange-400 bg-opacity-40"
      >
        <FaUndo />
      </button>
    </div>
  );
};

export default Undo;
