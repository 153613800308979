import React from "react";
import { SoundsInfo } from "../Lists/SoundInfo";

const Sound = ({ togglePlay, sounds, setVolume, playing }) => {
  return SoundsInfo.map((sound, index) => {
    return (
      <div
        title={sound.Name}
        key={index}
        className="hover:scale-105 transition-transform duration-300 rounded-full h-40 w-40 inline-flex flex-col items-center justify-center opacity-50 cursor-pointer relative"
      >
        <div onClick={() => togglePlay(index)} className="cursor-pointer">
          {sound.Icon}
        </div>
        <div>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            defaultValue={sounds[index]?.volume()}
            onChange={(e) => setVolume(sounds[index], e.target.value)}
            className={`mt-2 w-20 h-2 bg-gray-200 rounded-full appearance-none range-thumb:outline-none range-thumb:h-6 range-thumb:w-6 range-thumb:bg-white range-thumb:shadow-md range-track:h-2 ${
              playing[index] ? "block" : "hidden"
            }`}
          />
        </div>
      </div>
    );
  });
}

export default Sound;
