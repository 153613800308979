import bird from './Birds.mp3';
import forest from './BirdsInJungle.mp3';
import waterStream from './WaterStream.mp3';
import bonFire from './BonFire.mp3';
import brainFeverBird from './BrainFeverBird.mp3';
import submarine from './Bubble.mp3';
import bubble from './HeavyBubbles.mp3'
import rain from './RainOnRoof.mp3';
import thunder from './Thunderstorm.mp3';
import waves from './Waves.mp3';
import wind from './Wind.mp3';
import publicPlace from './PublicPlace.mp3';
import plane from './InThePlane.mp3';
import train from './Train.mp3';
import seaShore from './SeaShor.mp3';
import seagle from './Seagle.mp3';

export const BirdSound = bird;
export const ForestSound = forest;
export const WaterStreamSound = waterStream;
export const BonFireSound = bonFire;
export const NightSound = brainFeverBird;
export const SubmarineSound = submarine;
export const BubbleSound = bubble;
export const RainSound = rain;
export const ThunderSound = thunder;
export const WavesSound = waves;
export const WindSound = wind;
export const PublicPlaceSound = publicPlace;
export const PlaneSound = plane;
export const SeaShoreSound = seaShore;
export const TrainSound = train;
export const SeagleSound = seagle;
