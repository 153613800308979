import React, { useState } from "react";
import { Link } from "react-router-dom";
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <React.Fragment>
      <header className="fixed top-0 left-0 w-full z-50 backdrop-blur-md bg-orange-100 bg-opacity-40 drop-shadow-2xl">
        <nav className="flex justify-between items-center max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-left drop-shadow-2xl">
            <h2 className="text-xl font-bold">
              <Link to="/">Pure Sounds</Link>
            </h2>
          </div>

          <div className="flex items-center hidden md:block drop-shadow-2xl">
            <Link to="/" className="text-gray-700 hover:text-gray-900 ml-8">
              Home
            </Link>
            <Link
              to="/videos"
              className="text-gray-700 hover:text-gray-900 ml-8"
            >
              Videos
            </Link>
          </div>

          <div className="flex justify-between items-center p-4 text-white md:hidden">
            <div
              className="flex flex-col cursor-pointer "
              onClick={handleMenuToggle}
            >
              <div
                className={`w-6 h-1 bg-black mb-1 transition-transform duration-300 ${
                  menuOpen ? "transform rotate-45 translate-y-2" : ""
                }`}
              />
              <div
                className={`w-6 h-1 bg-black mb-1 opacity-0 transition-transform duration-300 ${
                  menuOpen ? "transform translate-x-full" : ""
                }`}
              />
              <div
                className={`w-6 h-1 bg-black transition-transform duration-300 ${
                  menuOpen ? "transform -rotate-45 -translate-y-2" : ""
                }`}
              />
            </div>
          </div>
        </nav>
        {menuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 backdrop-blur-md bg-orange-100 bg-opacity-40 drop-shadow-2xl p-4 m-4 rounded-lg transition-all duration-300 transform translate-y-2">
            <Link
              to="/"
              className="hover:text-gray-900 block py-2"
            >
              Home
            </Link>
            <Link
              to="/videos"
              className="hover:text-gray-900 block py-2"
            >
              Videos
            </Link>
          </div>
        )}
      </header>
    </React.Fragment>
  );
};

export default Header;
