export const videos = [
    {
      id: 1,
      title: "Coastal Calm: Enjoying the Peaceful Scenery of the Seashore",
      description: "Escape to the calming serenity of the seashore with this beautiful footage of waves crashing against the sandy beach.",
      url: "https://www.youtube.com/embed/KAxoJvFPr-M",
    },
    {
      id: 2,
      title: "Flying High: The Beauty of Air Travel - 4K",
      description: "Welcome aboard this peaceful flight journey! Sit back, relax, and enjoy the breathtaking view of the world from above.",
      url: "https://www.youtube.com/embed/XqmuaycqgoU",
    },
    {
      id: 3,
      title: "Beach 4K Paradise Relaxation Music | Meditation Relaxing Music | Nature Sounds",
      description: "Beautiful Relaxing Music with Beach Wind - Ocean Wave sounds are ideal for Sleeping, Meditation, Zen, Yoga, Stress Relief",
      url: "https://www.youtube.com/embed/Wm_gXhbt3tw",
    },
    {
      id: 4,
      title: "Relax and Unwind with the Soothing Sounds of Nature",
      description: "Beautiful Relaxing Music with Beach,Ocean Wave sounds are ideal for Sleeping, Meditation, Zen, Yoga, Stress Relief",
      url: "https://www.youtube.com/embed/k9H2vx8I4Eg",
    },
    {
      id: 5,
      title: "Beautiful Ocean Sunset Relax Music",
      description: "Experience the serene beauty of a breathtaking ocean sunset with this relaxing music video.",
      url: "https://www.youtube.com/embed/Y5whUk8ikT0",
    },
    {
      id: 6,
      title: "Wonderful Ocean Wave Sound for relax mind | 4K Ocean Video",
      description: "Whether you're looking to unwind after a long day, meditate, or simply enjoy the beauty of nature, this video will transport you to a place of pure relaxation and rejuvenation.",
      url: "https://www.youtube.com/embed/7jLgVJ0DVF0",
    },
    {
      id: 7,
      title: "Nature relax with Sea Shores",
      description: "The Nature Relax presents a piece of calm music with different Sea Shores to relax your body & mind with nature.",
      url: "https://www.youtube.com/embed/pmZNQi7RHIY",
    },
    {
      id: 8,
      title: "Find Peace in Nature: A Relaxing Visual Journey 🦚",
      description: "Immerse yourself in the serene beauty of forests, streams, and gentle rain, and let the peaceful melodies of this calming music wash away your stress and tension.",
      url: "https://www.youtube.com/embed/fToLNufVZAs",
    },
    {
      id: 9,
      title: "Fall a Sleep With Powerful Ocean Waves Sound On Rocky Beach",
      description: "Nature Relax Presents Powerful ocean wave sounds for relaxing your mind & best help in sleeping!",
      url: "https://www.youtube.com/embed/avOSdAR01v0",
    },
  ];