import React, { useState, useEffect } from "react";
import { Howl } from "howler";
import { SoundsInfo } from "../Lists/SoundInfo";
import Undo from "./Undo";
import Sound from "./Sound";

const DefaultVolume = 0.3;
const Loop = true;

const NatureTouch = () => {
  const [sounds, setSounds] = useState([]);
  const [playing, setPlaying] = useState([false, false]);

  const setVolume = (sound, volume) => {
    if (sound && sound.state() === "loaded") {
      sound.volume(volume);
    }
  };

  useEffect(() => {
    // Load sounds
    const soundList = [];
    SoundsInfo.forEach((sound, index) => {
      soundList.push(
        new Howl({ src: [sound.Sound], volume: DefaultVolume, loop: Loop })
      );
    });
    setSounds(soundList);

    // eslint-disable-next-line
  }, []);

  const togglePlay = (index) => {
    if (sounds[index]) {
      if (playing[index]) {
        sounds[index].stop();
      } else {
        sounds[index].play();
      }
      setPlaying((prevPlaying) => {
        const newPlaying = [...prevPlaying];
        newPlaying[index] = !prevPlaying[index];
        return newPlaying;
      });
    }
  };

  const handleResetClick = (next) => {
    sounds.forEach((sound, index) => {
      if (sounds[index]) {
        sounds[index].stop();
        setPlaying((prevPlaying) => {
          const newPlaying = [...prevPlaying];
          newPlaying[index] = false;
          return newPlaying;
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="mt-20 flex flex-col items-center justify-center text-white">
        <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 gap-8 justify-center">
          <Sound togglePlay={togglePlay} sounds={sounds} setVolume={setVolume} playing={playing} />
        </div>
      </div>

      <Undo handleResetClick={handleResetClick} />
    </React.Fragment>
  );
};

export default NatureTouch;
