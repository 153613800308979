import React from "react";

const VideoCard = ({ video }) => {
  return (
    <div className="cursor-pointer hover:scale-105 transition-transform duration-300 bg-gray-300 bg-opacity-60 drop-shadow-2xl max-w-sm rounded-lg overflow-hidden shadow-lg">
      <div className="relative">
        <iframe
          title="YouTube video player"
          className="w-full h-full"
          src={video.url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="p-4">
        <a href={video.url} target="_blank" rel="noreferrer" className="block font-semibold text-lg mb-2">
          {video.title}
        </a>
        <p className="text-gray-800 text-sm">{video.description}</p>
      </div>
    </div>
  );
};

export default VideoCard;
