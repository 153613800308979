import { ReactComponent as Logo } from '../Icons/PureSoundsLogo.svg';
import { ReactComponent as Bird } from '../Icons/Bird.svg';
import { ReactComponent as Forest } from '../Icons/Forest.svg';
import { ReactComponent as WaterStream } from '../Icons/WaterStream.svg';
import { ReactComponent as BonFire } from '../Icons/BonFire.svg';
import { ReactComponent as Night } from '../Icons/Night.svg';
import { ReactComponent as Submarine } from '../Icons/Submarine.svg';
import { ReactComponent as Bubble } from '../Icons/Bubble.svg';
import { ReactComponent as Rain } from '../Icons/Rain.svg';
import { ReactComponent as Thunder } from '../Icons/Thunder.svg';
import { ReactComponent as Waves } from '../Icons/Waves.svg';
import { ReactComponent as Wind } from '../Icons/Wind.svg';
import { ReactComponent as PublicPlace } from '../Icons/PublicPlace.svg';
import { ReactComponent as Plane } from '../Icons/Plane.svg';
import { ReactComponent as SeaShore } from '../Icons/SeaShore.svg';
import { ReactComponent as Train } from '../Icons/Train.svg';
import { ReactComponent as Seagle } from '../Icons/Seagle.svg';

export const AppLogo = Logo;
export const BirdIcon = Bird;
export const ForestIcon = Forest;
export const WaterStreamIcon = WaterStream;
export const BonFireIcon = BonFire;
export const NightIcon = Night;
export const SubmarineIcon = Submarine;
export const BubbleIcon = Bubble;
export const RainIcon = Rain;
export const ThunderIcon = Thunder;
export const WavesIcon = Waves;
export const WindIcon = Wind;
export const PublicPlaceIcon = PublicPlace;
export const PlaneIcon = Plane;
export const SeaShoreIcon = SeaShore;
export const TrainIcon = Train;
export const SeagleIcon = Seagle;
