import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
// import MyList from "./Components/MyList";
import NatureTouch from "./Components/NatureTouch";
import VideoList from "./Components/VideoContent";

const App = () => {
  const [backgroundClassName, setBackgroundClassName] = useState(
    "bg-gradient-to-br from-pink-500 to-purple-600"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundClassName(getRandomClassName());
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const getRandomClassName = () => {
    const classList = [
      "bg-gradient-to-br from-red-500 to-yellow-500",
      "bg-gradient-to-br from-green-500 to-blue-500",
      "bg-gradient-to-br from-pink-500 to-purple-600",
      "bg-gradient-to-r from-cyan-500 to-blue-500",
      "bg-gradient-to-r from-sky-500 to-indigo-500",
      "bg-gradient-to-r from-violet-500 to-fuchsia-500",
      "bg-gradient-to-r from-purple-500 to-pink-500",
    ];
    const randomIndex = Math.floor(Math.random() * classList.length);
    return classList[randomIndex];
  };

  return (
    <React.Fragment>
      <div className={`App ${backgroundClassName} flex flex-col min-h-screen`}>
        <Header />
        <main className="flex-grow z-0">
          <Routes>
            <Route path="/" element={<NatureTouch />} />
            <Route path="/videos" element={<VideoList />} />
            {/* <Route path="/my-list" element={<MyList />} /> */}
          </Routes>
        </main>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default App;
