import {
  BirdSound,
  ForestSound,
  RainSound,
  WaterStreamSound,
  BonFireSound,
  NightSound,
  SubmarineSound,
  BubbleSound,
  ThunderSound,
  WavesSound,
  WindSound,
  PublicPlaceSound,
  PlaneSound,
  TrainSound,
  SeaShoreSound,
  SeagleSound
} from "../Sounds/index";
import {
  BirdIcon,
  ForestIcon,
  RainIcon,
  WaterStreamIcon,
  BonFireIcon,
  NightIcon,
  SubmarineIcon,
  BubbleIcon,
  ThunderIcon,
  WavesIcon,
  WindIcon,
  PublicPlaceIcon,
  PlaneIcon,
  TrainIcon,
  SeaShoreIcon,
  SeagleIcon
} from "../Icons/index";

const IconSize = 24;

export const SoundsInfo = [
  {
    Name: "Bird",
    Icon: <BirdIcon size={IconSize} alt="A colorful bird perched on a tree branch, singing its melodic song" />,
    Sound: BirdSound,
  },
  {
    Name: "Seagle",
    Icon: <SeagleIcon size={IconSize} alt="A flock of seagulls flying over an ocean, creating a cacophony of seagull sounds" />,
    Sound: SeagleSound,
  },
  {
    Name: "Forest",
    Icon: <ForestIcon size={IconSize} alt="A misty forest with birds chirping and leaves rustling, creating tranquil forest sounds" />,
    Sound: ForestSound,
  },
  {
    Name: "Rain",
    Icon: <RainIcon size={IconSize} alt="A gentle rain shower falling onto a rooftop, creating soothing rain sounds" />,
    Sound: RainSound,
  },
  {
    Name: "Stream",
    Icon: <WaterStreamIcon size={IconSize} alt="A babbling brook in a lush forest, creating peaceful stream sounds" />,
    Sound: WaterStreamSound,
  },
  {
    Name: "BonFire",
    Icon: <BonFireIcon size={IconSize} alt="A roaring bonfire with flames and crackling wood, creating warm and cozy bonfire sounds" />,
    Sound: BonFireSound,
  },
  {
    Name: "Night",
    Icon: <NightIcon size={IconSize} alt= "A starry night sky with crickets chirping and the sound of a distant owl, creating serene and peaceful night sounds" />,
    Sound: NightSound,
  },
  {
    Name: "Submarine",
    Icon: <SubmarineIcon size={IconSize} alt="A submarine diving deep into the ocean, with propellers creating underwater sounds and bubbles rising to the surface" />,
    Sound: SubmarineSound,
  },
  {
    Name: "Bubble",
    Icon: <BubbleIcon size={IconSize} alt="A glass filled with sparkling water, with bubbles rising to the surface and creating delightful bubble sounds" />,
    Sound: BubbleSound,
  },
  {
    Name: "Thunder",
    Icon: <ThunderIcon size={IconSize} alt="A lightning bolt striking a tree during a thunderstorm, creating loud and powerful thunder sounds" />,
    Sound: ThunderSound,
  },
  {
    Name: "Wave",
    Icon: <WavesIcon size={IconSize} alt="A calm ocean with waves gently lapping against the shore, creating peaceful wave sounds" />,
    Sound: WavesSound,
  },
  {
    Name: "Wind",
    Icon: <WindIcon size={IconSize} alt="A tall tree swaying in the wind, creating natural and soothing wind sounds" />,
    Sound: WindSound,
  },
  {
    Name: "PublicPlace",
    Icon: <PublicPlaceIcon size={IconSize} alt="A busy city street with people and vehicles moving, creating bustling public place sounds" />,
    Sound: PublicPlaceSound,
  },
  {
    Name: "Plane",
    Icon: <PlaneIcon size={IconSize} alt="A jet plane flying through a blue sky, emitting a loud engine noise" />,
    Sound: PlaneSound,
  },
  {
    Name: "Train",
    Icon: <TrainIcon size={IconSize} alt="A steam locomotive chugging down a railway track, creating rhythmic train sounds" />,
    Sound: TrainSound,
  },
  {
    Name: "SeaShore",
    Icon: <SeaShoreIcon size={IconSize} alt="A sandy beach with waves crashing onto the shore, creating soothing sea shore sounds" />,
    Sound: SeaShoreSound,
  },
];
