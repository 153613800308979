import React from "react";
import VideoCard from "./VideoCard";
import { videos } from "../Lists/Videos";

const VideoList = () => {
  return (
    <div className="m-10 grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {videos
        .map((video) => (
          <VideoCard key={video.id} video={video} />
        ))}
    </div>
  );
};

export default VideoList;
