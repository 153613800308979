import { socialMedia } from '../Lists/Socials';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 mt-auto">
      <div className="max-w-screen-xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="sm:w-auto mb-4 sm:mb-0">
            <p className="text-lg text-gray-300 font-bold">
              Made with <span className="text-red-500">&hearts;</span> by Kevin
            </p>
          </div>
          <div className="sm:w-auto">
            <div className="flex items-center">
              {socialMedia.map((sm, index) => (
                <a
                  key={index}
                  href={sm.Link}
                  target='_blank'
                  rel="noreferrer"
                  className="text-gray-300 hover:text-gray-400 mx-2"
                >
                  <span className="sr-only">{sm.Name}</span>
                  {sm.Icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-8 flex justify-center">
          <p className="text-sm text-gray-500">
            &copy; 2023 Nature Touch. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
